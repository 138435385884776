const data = [
  {
    canal:"Orange Money",
    desc:"Pour obtenir votre code d'autorisation Orange Money, merci de taper #144*82#. Puis entrez votre code secret et validez."
  },
  {
    canal:"MTN Mobile Money",
    desc:"Pour confirmer le paiement Mtn Money, merci de taper #133#, choisir l'option 1 puis l'option 1. Puis entrez votre code secret et validez."
  },
  {
    canal:"Moov Money",
    desc:"Pour confirmer le paiement Moov Money, vous recevrez une notification de paiement. Suivez la procédure et validez."
  },
  {
    canal:"Wave",
    desc:"Pour confirmer le paiement Wave, merci de suivre le lien de paiement qui vous sera envoyé . Puis confirmez directement dans votre application wave "
  },
  {
    canal:"wizall",
    desc:"....."
  }
]

export default data;