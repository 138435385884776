import { createContext, useState } from "react";

export const userInfoToPrintContext = createContext();

const UserInfoToPrintProvider = ({ children }) => {
    const [userInfoToPrint, setUserInfoToPrint] = useState(null);

    return (
        <userInfoToPrintContext.Provider value={{ userInfoToPrint, setUserInfoToPrint }}>
            {children}
        </userInfoToPrintContext.Provider>
    );
}

export default UserInfoToPrintProvider;
