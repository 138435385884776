import React, { useState } from 'react';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import { Link } from 'react-router-dom';
import aboutData from '../data/aboutData';

const AboutsUsPage = () => {

  const [activeIndex, setActiveIndex] = useState(null);
  const [offre,setOffre] = useState("1")

  const handleToggleClass = (index) => {
    setActiveIndex(index);
  };


  return (
    <>
      <Navbar />
      <div class="center-img">
        <a href="pay.html">
          <img style={{ margin: "10px auto", width: "300px" }} src="images/logo-aigle1.png" alt="" />
        </a>
      </div>
      <div class="wrapper">
        <div class="inner">
          <ul class="breadcrumb">
            <li><Link to={"/"}>Aigle Send</Link></li>
            <li>Qui sommes nous</li>
          </ul>
          <div class="">
            <div class="welcome">
              <p>
                Bienvenue sur Aigle Send
              </p>
            </div>
            <h2>Une équipe de professionnels dévoué pour vous.</h2>
            <div class="paragraph-center">
              <p>
                Aigle Send est une solution développée par <span> Compagnie des Services Numériques.</span>
              </p>
              <div class="">
                Nous accélérons la digitalisation des
                entreprises en Afrique dans le cas de leurs activités (recevoir
                des paiements, émettre des paiements et
                assurer une meilleure gestion).
              </div>
              <p>
                Avec la plateforme Hyksos, vous payez chaque bénéficiaire sur son opérateur Mobile Money.
              </p>
              <p>
                Devenez distributeur mobile money ou commerce marchand.
                Avec un seul numero marchand, debitez n'importe quel compte mobile money et carte Visa ou Mastercard
              </p>
            </div>
            <div class="line">
              <hr />
            </div>
            <div id="wizard">
              <div class="row flex">
                <div class="col-md-4">
                  <div className="side">
                    <ul>
                      {aboutData.map((link, index) => (
                        <li key={link.id} className={activeIndex === index ? 'active' : ''} onClick={() => {handleToggleClass(index);setOffre(link.id)}}>
                          {link.offre}
                        </li>
                      ))}
                      
                    </ul>
                  </div>
                </div>
                <div class="col-md-8">
                  {aboutData.map((link, index) => (
                    link.id === offre && (
                      <div class="form-row" key={index}>
                        {link.description}
                      </div>
                    )
                  ))}
                </div>
              </div>

              {/* <h4>L'offre paiement en masse </h4>
              <section class="section-style">
                <div class="form-row">
                  Idéale pour toutes les entreprises qui souhaitent payer leurs personels par mobile money ou carte VISA.
                  <p>Pour cela il suffit simplement de remplir un seul fichier EXCEL sur notre plateforme, avec les
                    informations et le numéro du compte Mobile Money(Orange Money, MTN Money, Moov Money, Wizall, Carte
                    VISA, ...). </p>
                  <p>
                    Une fois le fichier chargé chaque bénéficiaire reçoit sa paye sur son compte.
                  </p>
                  <a href="https://www.mobilepay-ci.tech/account/">
                    <button type="submit"> Souscrire à l'offre </button>
                  </a>
                </div>
              </section>

              
              <h4>L'offre Collecte</h4>
              <section class="section-style">
                <div class="form-row">
                  Idéal pour les microfinances et toutes les structures dont l'activté principale est basées sur le
                  recouvrement.
                  <p>
                    Elle permet aux clients d'effectuer leurs versements de créances à partir de n'importe quel point Mobile
                    Money du quartier ou du marché(Orange Money, MTN Money, Moov Money, Wizall, Wave, ...).

                  </p>
                  <a href="https://www.mobilepay-ci.tech/account/">
                    <button type="submit"> Souscrire à l'offre </button>
                  </a>
                </div>
              </section>

              
              <h4>Distribution Aigle Send</h4>
              <section class="section-style">
                <div class="form-row">
                  En moins de 72 heures mettez en place votre point de vente Mobile Money tous les pérateurs y compris.
                  Avec un seul numéro, une seul carte SIM et seul compte UVE.

                  <a href="https://www.mobilepay-ci.tech/account/">
                    <button type="submit"> Souscrire à l'offre </button>
                  </a>
                </div>
              </section>

              
              <h4><a href=""> L'offre de paiement marchand </a> </h4>
              <section id="anchor" class="section-style">
                <div class="form-row">
                  Vous avez un commerce et vous souhaitez que vos clients vous règle par Mobile Money.
                  <p>
                    Le package MobilePay est idéal.
                  </p>
                  <p>
                    Un seul agrément vous permet de recevoir les paiementd de tous les Mobile Money disponible dans le pays.
                  </p>
                  <a href="https://www.mobilepay-ci.tech/account/">
                    <button type="submit"> Souscrire à l'offre </button>
                  </a>
                </div>
              </section>

              
              <h4>L'offre de distribution de crédit de communication</h4>
              <section class="section-style">
                <div class="form-row">
                  Vous souhaitez créer un point de distribution communément appelé cabine.
                  <p>
                    Le package MobilePay vous permet de distribuer du crédit de communication soit à partir de notre
                    plateforme, soit à partir de votre smartphone avec un seul compte UVE.
                  </p>
                  <a href="http:/www.google.com/"><button type="submit"> Souscrire à l'offre </button></a>

                </div>
              </section>

              <h4>Le rappel automatique</h4>
              <section>
                <div class="form-row">
                  <p>
                    Ce mécanisme permet de régler les employés de nos partenaires automatiquement et leurs différents
                    factures.
                  </p>
                  
                  <p>
                    Dans le secteur de la distribution, les factures électroniques des fournisseurs sont automatiquement
                    réglés.
                  </p>
                </div>

              </section>
              
              <h4>Sécurisé</h4>
              <section class="section-style">
                <div class="form-row">
                  <p>
                    Notre plateforme offre une sécurité garantie, cependant Aigle Send permet entre autre.
                  </p>

                  <ul>
                    <li>
                      La suppression des problèmes dû à la circulation du cash.
                    </li>
                    <li>
                      Toutes les transactions sont tracées ce qui accroit la sécurité.
                    </li>
                    <li>
                      Vous pouvez voir tous vos opérations sur la plateforme.
                    </li>
                  </ul>

                </div>
              </section>

              
              <h4>Unique</h4>
              <section class="section-style">
                <div class="form-row">
                  <p>
                    Unique car

                    Aigle Send permette les transactions des carte SIM dediée.

                    Elles sont capable d'envoyer les details de tous vos achats vers nos services.
                  </p>

                </div>
              </section>
              
              <h4>Les API</h4>
              <section class="section-style">
                <div class="form-row">


                  <p>
                    Les API de Aigle Send
                    sont fournies par nos partenaires,le cas echéant
                    elles sont fournies à nos puces dédiées.
                  </p>

                </div>
              </section>
              
              <h4>Le parcours</h4>
              <section>
                <div class="form-row">
                  En tant qu'utilisateur des services de DEVLOGY, voici le parcours:
                  <p>
                    1. Il existe un compte intermédiaire d'UVE dénommé : compte Messenka
                  </p>
                  <p>
                    2- Le compte intermédiaire Messenka peut créditer n'importe quel opérateur mobile money (en Côte
                    d'ivoire : ORANGE
                    MONEY, MTN MOBILE MONEY, MOOV MONEY et les cartes VISA délivrées par UBA)
                  </p>
                  <p>
                    3- Le compte intermédiaire MESSENKA peut être crédité en UVE par n'importe quel opérateur mobile money
                    (en Côte d'ivoire :
                    ORANGE MONEY, MTN MOBILE MONEY, MOOV MONEY et les cartes VISA délivrées par UBA)
                  </p>
                  <p>
                    4- Les requête peuvent être envoyé vers le server Messenka de deux manière ; soit par données mobiles,
                    soit par sms et dans ce
                    cas, un server sms achemine ces requêtes vers l'url de l'opérateur mobile money. L'API est intégré sur
                    le server de données
                    Messenka pour traiter ces données pour qu'elles soient conforme aux normes (type de données et sécurité)
                    de l'opérateur
                    mobile money.
                  </p>

                </div>

              </section>

              
              <h4>Le schema</h4>
              <section class="section-style">
                <div class="image-holder">
                  <img src="images/schema.png" alt="" />
                </div>

              </section> */}

            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutsUsPage;