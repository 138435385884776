import { Box, Button, ButtonBase, Modal } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function ModalNotification({ open, actionOpen, responseTransaction, responseFromPayment, walletID, linkToPayWave }) {
    const navigate = useNavigate();
    const [displayReceiptWave, setDisplayReceiptWave] = useState(true);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        borderRadius: 5,
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };
    return (
        responseTransaction === null || responseTransaction === ""
            ?
            (<Modal
                open={open}>
                <Box sx={{ ...style, width: "60%" }}>
                    <p className="font-semibold text-center" id="child-modal-description">
                        Nous enregistrons votre transaction. Patientez quelques minutes.
                    </p>
                    <div className="flex justify-center">
                        <div>
                            <img src="./gif/CubeLoading.gif" />
                        </div>
                    </div>
                    {/* <Button>Close Child Modal</Button> */}
                </Box>
            </Modal>)

            :

            responseTransaction === "successDirectMobileMoney"
                ?
                (<Modal
                    open={open}
                >
                    <Box sx={{ ...style, bgcolor: "rgb(167 243 208)", width: "60%" }}>
                        <p className="font-semibold text-center" id="child-modal-description">
                            Votre transaction par mobile money a été bien enregistré !
                        </p>
                        <div className="flex justify-center">
                            <div className="h-16">
                                <img className="h-full object-contain" src="./images/checked.png" />
                            </div>
                        </div>
                        <p className="font-semibold text-center mt-3" id="child-modal-description">
                            Accéder à votre reçu en cliquant sur ce bouton ci-dessous
                        </p>
                        <div className="flex justify-center relative">
                            <Button className="absolute top-0 w-fit" color="success" variant="contained" onClick={() => { navigate(`/${walletID}/${responseFromPayment?.id}`) }}>Accéder au reçu de votre transaction</Button>
                        </div>
                        {/* <Button>Close Child Modal</Button> */}
                    </Box>
                </Modal>)

                :

                responseTransaction === "successDirectCreditCard"
                    ?
                    (<Modal
                        open={open}
                    >
                        <Box sx={{ ...style, bgcolor: "rgb(167 243 208)", width: "60%" }}>
                            <p className="font-semibold text-center" id="child-modal-description">
                                Votre transaction par carte de crédit a été bien enregistré !
                            </p>
                            <div className="flex justify-center">
                                <div className="h-16">
                                    <img className="h-full object-contain" src="./images/checked.png" />
                                </div>
                            </div>
                            <p className="font-semibold text-center mt-3" id="child-modal-description">
                                Accéder à votre reçu en cliquant sur ce bouton ci-dessous
                            </p>
                            <div className="flex justify-center relative">
                                <Button className="absolute top-0 w-fit" color="success" variant="contained" onClick={() => { navigate(`/${walletID}/${responseFromPayment?.id}`) }}>Accéder au reçu de votre transaction</Button>
                            </div>
                            {/* <Button>Close Child Modal</Button> */}
                        </Box>
                    </Modal>)

                    :

                    responseTransaction === "pendingWave"
                        ?
                        (<Modal
                            open={open}
                        >
                            <Box sx={{ ...style, bgcolor: "rgb(186 230 253)", width: "60%" }}>
                                <p className="font-semibold text-center" id="child-modal-description">
                                    Veuillez cliquez sur ce lien afin de finaliser votre paiement avec Wave
                                </p>
                                <div className="flex justify-center relative">
                                    <Button className="absolute top-0 w-fit" variant="contained" onClick={() => { window.location.replace(linkToPayWave); setDisplayReceiptWave(true) }}>Cliquez pour finaliser avec Wave</Button>
                                </div>

                                {
                                    displayReceiptWave && (
                                        <>
                                            <p className="font-semibold text-center mt-3" id="child-modal-description">
                                                Accéder à votre reçu en cliquant sur ce bouton ci-dessous
                                            </p>
                                            <div className="flex justify-center relative">
                                                <Button className="absolute top-0 w-fit" variant="contained" color="success" onClick={() => { navigate(`/${walletID}/${responseFromPayment?.id}`) }}>Accéder au reçu de transaction</Button>
                                            </div>
                                        </>
                                    )
                                }

                                {/* <Button>Close Child Modal</Button> */}
                            </Box>
                        </Modal>)

                        :

                        responseTransaction === "error"
                            ?
                            (<Modal
                                open={open}
                                onClose={() => actionOpen(false)}>
                                <Box sx={{ ...style, bgcolor: "rgb(254 226 226)", width: "60%" }}>
                                    <p className="font-semibold text-center" id="child-modal-description">
                                        Votre paiement a été réfusé.
                                    </p>
                                    <p className="font-semibold text-center" id="child-modal-description">
                                        Raison(s) : Assurez d'avoir une connexion wifi ou credit data optimale !
                                    </p>
                                    <div className="flex justify-center relative">
                                        <Button className="absolute top-0 w-max" variant="contained" color="error" onClick={() => actionOpen(false)}>Cliquer pour fermer</Button>
                                    </div>
                                    {/* <Button>Close Child Modal</Button> */}
                                </Box>
                            </Modal>)

                            :

                            ""
    )
}