import React, { useEffect, useState } from 'react';
import data from '../data/dataChoice';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import ModalNotification from '../components/ModalNotification';
import toast, { Toaster } from 'react-hot-toast';
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input'

const PaymentPage = () => {
  const [open, setOpen] = useState(false)
  const { walletID } = useParams();
  const [type, setType] = useState("");
  const [canal, setCanal] = useState(null);
  const [walletInfo, setWalletInfo] = useState()
  const [responseTransaction, setResponseTransaction] = useState(null)
  const [responseFromPayment, setResponseFromPayment] = useState("")
  const [linkToPayWave, setLinkToPayWave] = useState("https://pay.wave.com/c/cos-1qp8kffn021wt?a=200.00&c=XOF&m=Aigle%20%2A%20HYKSOS")


  if (walletID === undefined) {
    window.localStorage.setItem("walletID", walletID)
    var storageWalletInfo = window.localStorage.getItem('walletID')
  }

  console.log("storage", storageWalletInfo)
  const navigate = useNavigate();

  useEffect(() => {
    const handleGetInformations = async () => {
      await axios.get(`https://api.mobiletransaction4africa.tech/aigle/api/v1/my-account`, {
        headers: {
          Authorization: `Bearer ${walletID}`
        }
      })
        .then(res => {
          setWalletInfo(res?.data)
          // const storageWalletInfo = window.localStorage.setItem('walletInfo', account)
          console.log("reponse", walletInfo)
        })
        .catch(err => console.log(err));
    }

    handleGetInformations()

  }, [])

  //Detect the use connection so that we will redirect him to error page if he doesn't have connection to pay
  //Stock the walletID in window.localStorage so that if connection is good, we will redirect him to the page
  window.addEventListener("offline", function (e) {
    console.log("hors ligne");
    navigate('/error')
  });
  // window.addEventListener("online", function (e) {
  //   console.log("connecté");
  //   navigate(`/${storageWalletInfo}`)
  // });

  const [formPaymentMobileMoney, setFormPaymentMobileMoney] = useState({
    canal: "",
    phone: "",
    montant: "",
    otp: "",
    phoneCode: ""
  })
  console.log(formPaymentMobileMoney)
  const [formPaymentCreditCard, setFormPaymentCreditCard] = useState({
    idClientCarte: "",
    numeroCarte: "",
    montant: "",
    phoneActif: "",
    nomCarte: "",
    phoneCode: ""
  })

  // const handleChangePhoneMobileMoney = (value) => {
  //   setFormPaymentMobileMoney({ ...formPaymentMobileMoney, phone: value })
  //   console.log("MobileMoney", formPaymentMobileMoney.phone);
  // }

  // const handleChangePhoneCreditCard= (value) => {
  //   setFormPaymentCreditCard({ ...formPaymentCreditCard, phoneActif: value })
  //   console.log("CreditCard", formPaymentCreditCard.phoneActif);
  // }

  const handlePhoneChangeMobileMoney = (value) => {
    const phoneNumber = parsePhoneNumber(value);
    setFormPaymentMobileMoney({
      ...formPaymentMobileMoney,
      phone: phoneNumber?.nationalNumber,
      phoneCode: `+${phoneNumber?.countryCallingCode}`,
    });
  };

  const handlePhoneChangeCreditCard = (value) => {
    const phoneNumber = parsePhoneNumber(value);
    setFormPaymentCreditCard({
      ...formPaymentCreditCard,
      phoneActif: phoneNumber?.nationalNumber,
      phoneActifCode: `+${phoneNumber?.countryCallingCode}`,
    });
  };

  const handleChangeCreditCard = () => {
    setType("credit-card");
  }
  const handleChangeMobileMoney = () => {
    setType("mobile-money");
  }

  const handleSubmitMobileMoney = async (e) => {
    e.preventDefault();
    console.log("data going to send", formPaymentMobileMoney)


    if (canal === "Orange Money" && formPaymentMobileMoney.otp === "") {
      toast.error("Veuillez saisir votre code OTP sinon la transaction ne marchera pas.")
      return
    } else {
      setOpen(true)

      if (formPaymentMobileMoney.canal !== "Wave") {
        axios.all([
          axios.post(`${process.env.REACT_APP_API_PAYMENT}`, {
            "beneficiaire": formPaymentMobileMoney.phone,
            "countryCode":formPaymentMobileMoney.phoneCode,
            "beneficiaireOmnivox": null,
            "idClientCarte": null,
            "numeroCarte": null,
            "montant": formPaymentMobileMoney.montant,
            "devise": "XOF",
            "canal": formPaymentMobileMoney.canal,
            "type": "Retrait",
            "otp": formPaymentMobileMoney.otp,
            "description": `Paiement QR Code le ${Date.now()} par ${formPaymentMobileMoney.phone}`,
            "submit": null
          }, {
            headers: {
              'Authorization': `Bearer ${walletInfo.walletId}`
            }
          }).then((response) => {
            setResponseTransaction("successDirectMobileMoney")
            setResponseFromPayment(response.data)
            console.log("response", response.data)
          })
            .catch((error) => { console.log(error); setResponseTransaction("error") }),
        ])
      }

      if (formPaymentMobileMoney.canal === "Wave") {
        axios.all([
          axios.post(`${process.env.REACT_APP_API_PAYMENT}`, {
            "beneficiaire": formPaymentMobileMoney.phone,
            "countryCode":formPaymentMobileMoney.phoneCode,
            "beneficiaireOmnivox": null,
            "idClientCarte": null,
            "numeroCarte": null,
            "montant": formPaymentMobileMoney.montant,
            "devise": "XOF",
            "canal": "Wave",
            "type": "Retrait",
            "otp": "",
            "description": `Paiement QR Code le ${Date.now()} par ${formPaymentMobileMoney.phone}`,
            "submit": null
          }, {
            headers: {
              'Authorization': `Bearer ${walletInfo.walletId}`
            }
          }).then((response) => {
            setResponseTransaction("pendingWave")
            setLinkToPayWave(response.data.paymentUrl)
            setResponseFromPayment(response.data)
            console.log("response", response.data)
          })
            .catch((error) => { console.log(error); setResponseTransaction("error") }),
        ])
      }
    }

  }

  const handleSubmitCreditCard = async (e) => {
    e.preventDefault();

    if (formPaymentCreditCard.idClientCarte === "" || formPaymentCreditCard.phoneActif === "" || formPaymentCreditCard.numeroCarte === "" || formPaymentCreditCard.montant === "" || formPaymentCreditCard.nomCarte === "") {
      toast.error("Veuillez bien renseigner les champs")
      return
    } else {
      setOpen(true)

      // setTimeout(() => {setResponseTransaction("error")}, 6000)
      axios.all([
        axios.post(`${process.env.REACT_APP_API_PAYMENT}`, {
          "beneficiaire": formPaymentCreditCard.phoneActif,
          "countryCode": formPaymentCreditCard.phoneCode,
          "beneficiaireOmnivox": null,
          "idClientCarte": formPaymentCreditCard.idClientCarte,
          "numeroCarte": formPaymentCreditCard.numeroCarte,
          "montant": formPaymentCreditCard.montant,
          "devise": "XOF",
          "canal": "Visa",
          "type": "Retrait",
          "otp": "",
          "description": `Paiement QR Code le ${Date.now()} par ${formPaymentCreditCard.nomCarte}`,
          "submit": null
        }, {
          headers: {
            'Authorization': `Bearer ${walletInfo.walletId}`
          }
        }).then((response) => {
          console.log("response", response.data)
          setResponseTransaction("successDirectCreditCard")
          setResponseFromPayment(response.data)
        }).catch((err) => { console.log("error", err); setResponseTransaction("error") })])
    }
  }


  return (
    <>
      <Navbar />

      <Toaster
        position="top-center"
        reverseOrder={false}
      />

      <ModalNotification
        open={open}
        actionOpen={setOpen}
        walletID={walletID}
        responseTransaction={responseTransaction}
        responseFromPayment={responseFromPayment}
        linkToPayWave={linkToPayWave} />

      <section class="payment-section">
        <div class="container">
          <div class="payment-wrapper">

            <div class="payment-right">
              <h1 className='text-center text-red-600 mb-3'>ATTENTION : Vous êtes sur le point d'effectuer une transaction au bénéfice de <b>{walletInfo?.compagnyName ? walletInfo?.compagnyName : walletInfo?.user.firstName + ' ' + walletInfo?.user.lastName} , Contact : {walletInfo?.user.phone}</b> </h1>
              <form class="payment-form">
                <h1 class="font-semibold md:text-xl max_BeforeMd:text-md">Veuillez choisir votre mode de paiement :</h1>
                <div class="payment-method spec card-or-mobile-method">
                  <input type="radio" name="payment-method" id="mobile-money" />
                  <label for="mobile-money" class={type === "mobile-money" ? "payment-method-item card-or-mobile " : "payment-method-item card-or-mobile "} onClick={handleChangeMobileMoney}>
                    <img src="images/mobile-money.png" alt="" />
                    <span >Mobile money</span>
                  </label>

                  <input type="radio" name="payment-method" id="credit-card" class={type === "credit-card" ? "payment-method-item card-or-mobile " : "payment-method-item card-or-mobile "} onClick={handleChangeCreditCard} />
                  <label for="credit-card" class="payment-method-item card-or-mobile">
                    <img src="images/card.png" alt="" />
                    <span style={{ fontSize: '12px' }}>Carte bancaire</span>
                  </label>
                </div>

                {type === "mobile-money" && (
                  <>
                    <div class="payment-method">
                      <input type="radio" name="payment-method" id="method-1" />
                      <label for="method-1" class="payment-method-item" onClick={() => { setCanal("Orange Money"); setFormPaymentMobileMoney({ ...formPaymentMobileMoney, canal: "Orange Money" }) }}>
                        <img src="images/orange.webp" alt="" />
                      </label>
                      <input type="radio" name="payment-method" id="method-2" />
                      <label for="method-2" class="payment-method-item" onClick={() => { setCanal("MTN Mobile Money"); setFormPaymentMobileMoney({ ...formPaymentMobileMoney, canal: "MTN Mobile Money" }) }}>
                        <img src="images/mtn.webp" alt="" />
                      </label>
                      <input type="radio" name="payment-method" id="method-3" />
                      <label for="method-3" class="payment-method-item" onClick={() => { setCanal("Moov Money"); setFormPaymentMobileMoney({ ...formPaymentMobileMoney, canal: "Moov Money" }) }}>
                        <img src="images/flooz.webp" alt="" />
                      </label>
                      <input type="radio" name="payment-method" id="method-4" />
                      <label for="method-4" class="payment-method-item" onClick={() => { setCanal("Wave"); setFormPaymentMobileMoney({ ...formPaymentMobileMoney, canal: "Wave" }) }}>
                        <img src="images/wave.png" alt="" />
                      </label>

                      {/* <input type="radio" name="payment-method" id="method-5" /> */}
                      <label for="method-5" class="payment-method-item bg-stone-300" onClick={() => { setCanal("wizall"); setFormPaymentMobileMoney({ ...formPaymentMobileMoney, canal: "wizall" }) }}>
                        <img src="images/wizall.png" alt="" />
                      </label>
                    </div>
                    <div class="payment-form-group">
                      <input type="number" placeholder=" " class="payment-form-control" id="montant" onChange={(e) => setFormPaymentMobileMoney({ ...formPaymentMobileMoney, montant: e.target.value })} />
                      <label for="montant" class="payment-form-label payment-form-label-required">Montant</label>
                    </div>

                    {/* <div class="payment-form-group">
                      <input type="number" placeholder=" " class="payment-form-control" id="number-phone" onChange={(e) => setFormPaymentMobileMoney({ ...formPaymentMobileMoney, phone: e.target.value })} />
                      <label for="number-phone" class="payment-form-label payment-form-label-required">Indicatif + Numéro de compte</label>
                    </div> */}

                    <div class="payment-form-group">
                    <PhoneInput
                      className="p-4 border rounded-lg focus:ring-blue-500"
                      defaultCountry="CI"
                      placeholder="Entrez votre numéro de télephone"
                      value={formPaymentMobileMoney.phoneActif}
                      onChange={handlePhoneChangeMobileMoney}
                      required
                    />

                    </div>
                    {
                      canal === "Orange Money" && (
                        <div class="payment-form-group">
                          <input type="number" placeholder=" " class="payment-form-control" id="confirmation" onChange={(e) => setFormPaymentMobileMoney({ ...formPaymentMobileMoney, otp: e.target.value })} />
                          <label for="confirmation" class="payment-form-label payment-form-label-required">Code OTP</label>
                        </div>

                      )
                    }

                    {data.map((info, index) => (
                      info.canal === canal && (
                        <div className="payment-form-group" key={index}>
                          <textarea
                            type="text"
                            placeholder=" "
                            className="payment-form-control"
                            id="info"
                            disabled
                            value={info.desc}
                            rows={7}
                          />
                        </div>
                      )
                    ))}
                  </>
                )}

                {type === "credit-card" && (
                  <>
                    <div class="payment-method">
                      <input type="radio" name="payment-method" id="method-1" />
                      <label for="method-1" class="payment-method-item">
                        <img src="images/visa.webp" alt="" />
                      </label>
                      <input type="radio" name="payment-method" id="method-2" />
                      <label for="method-2" class="payment-method-item">
                        <img src="images/mastercard.webp" alt="" />
                      </label>
                    </div>
                    <div class="payment-form-group">
                      <input type="number" placeholder=" " class="payment-form-control" id="montant" onChange={(e) => setFormPaymentCreditCard({ ...formPaymentCreditCard, montant: e.target.value })} />
                      <label for="montant" class="payment-form-label payment-form-label-required">Montant</label>
                    </div>
                    <div class="payment-form-group">
                      <input type="text" placeholder=" " class="payment-form-control" id="card-name" onChange={(e) => setFormPaymentCreditCard({ ...formPaymentCreditCard, nomCarte: e.target.value })} />
                      <label for="card-name" class="payment-form-label payment-form-label-required">Nom sur la carte</label>
                    </div>
                    <div class="payment-form-group">
                      <PhoneInput
                      className="p-4 border rounded-lg focus:ring-blue-500"
                      defaultCountry="CI"
                      placeholder="Veuillez entrez votre numéro de téléphone"
                      value={formPaymentCreditCard.phoneActif}
                      onChange={handlePhoneChangeCreditCard}
                      required
                    />
                    </div>
                    <div class="payment-form-group">
                      <input type="number" placeholder=" " class="payment-form-control" id="card-number" onChange={(e) => setFormPaymentCreditCard({ ...formPaymentCreditCard, numeroCarte: e.target.value })} />
                      <label for="card-number" class="payment-form-label payment-form-label-required">Les 4 derniers numéros de la carte</label>
                    </div>
                    <div class="payment-form-group-flex">
                      <div class="payment-form-group">
                        <input type="number" placeholder=" " class="payment-form-control" id="cvv" onChange={(e) => setFormPaymentCreditCard({ ...formPaymentCreditCard, idClientCarte: e.target.value })} />
                        <label for="cvv" class="payment-form-label payment-form-label-required">CVV (N° au dos de la carte) </label>
                      </div>
                    </div>
                  </>
                )}

                {/* Button Submit payment */}
                {type === "mobile-money" && (
                  <button onClick={(e) => handleSubmitMobileMoney(e)} type='button' class="payment-form-submit-button">
                    <i class="ri-wallet-line"></i>
                    Payer avec Mobile Money
                  </button>
                )}

                {type === "credit-card" && (
                  <button type='button' class="payment-form-submit-button" onClick={(e) => handleSubmitCreditCard(e)}>
                    <i class="ri-wallet-line"></i>
                    Payer avec Credit Card
                  </button>
                )}
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PaymentPage;