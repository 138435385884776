const aboutData = [
  {
    id: 1,
    offre: "Paiement en masse",
    description: "Idéale pour toutes les entreprises qui souhaitent payer leurs personels par mobile money ou carte VISA.<br/> Pour cela il suffit simplement de remplir un seul fichier EXCEL sur notre plateforme, avec lesinformations et le numéro du compte Mobile Money(Orange Money, MTN Money, Moov Money, Wizall, CarteVISA, ...).<br/> Une fois le fichier chargé chaque bénéficiaire reçoit sa paye sur son compte."
  },
  {
    id: 2,
    offre: "Collecte",
    description: "Idéal pour les microfinances et toutes les structures dont l'activté principale est basées sur le recouvrement.<br/>Elle permet aux clients d'effectuer leurs versements de créances à partir de n'importe quel point Mobile Money du quartier ou du marché(Orange Money, MTN Money, Moov Money, Wizall, Wave, ...)."
  },
  {
    id: 3,
    offre: "Distribution",
    description: "En moins de 72 heures mettez en place votre point de vente Mobile Money tous les pérateurs y compris. Avec un seul numéro, une seul carte SIM et seul compte UVE."
  },
  { 
    id: 4,
    offre: "Paiement marchand",
    description: "Vous avez un commerce et vous souhaitez que vos clients vous règle par Mobile Money.<br/>Le package MobilePay est idéal.<br/> Un seul agrément vous permet de recevoir les paiementd de tous les Mobile Money disponible dans le pays."
  },
  {
    id: 5,
    offre: "Distribution de credit de communication",
    description: "Vous souhaitez créer un point de distribution communément appelé cabine.<br/>Le package MobilePay vous permet de distribuer du crédit de communication soit à partir de notre plateforme, soit à partir de votre smartphone avec un seul compte UVE."
  },
  {
    id: 6,
    offre: "Rappel automatique",
    description: "Ce mécanisme permet de régler les employés de nos partenaires automatiquement et leurs différents factures.<br/>Dans le secteur de la distribution, les factures électroniques des fournisseurs sont automatiquement réglés."
  },
  {
    id: 7,
    offre: "Sécurisé",
    description: "Notre plateforme offre une sécurité garantie, cependant Aigle Send permet entre autre.<br/>La suppression des problèmes dû à la circulation du cash.<br/>Toutes les transactions sont tracées ce qui accroit la sécurité.<br/>Vous pouvez voir tous vos opérations sur la plateforme."
  },
  {
    id: 8,
    offre: "Unique",
    description: "Unique car Aigle Send permette les transactions des carte SIM dediée. Elles sont capable d'envoyer les details de tous vos achats vers nos services."
  },
  {
    id: 9,
    offre: "Api",
    description: `Les API de Aigle Send sont fournies par nos partenaires,le cas echéant elles sont fournies à nos puces dédiées.`
  },
  {
    id:10,
    offre: "Parcours",
    description: `En tant qu'utilisateur des services de DEVLOGY, voici le parcours:<br/>1. Il existe un compte intermédiaire d'UVE dénommé : compte Messenka<br/>2- Le compte intermédiaire Messenka peut créditer n'importe quel opérateur mobile money (en Côte d'ivoire : ORANGE MONEY, MTN MOBILE MONEY, MOOV MONEY et les cartes VISA délivrées par UBA)<br/>3- Le compte intermédiaire MESSENKA peut être crédité en UVE par n'importe quel opérateur mobile money (en Côte d'ivoire : ORANGE MONEY, MTN MOBILE MONEY, MOOV MONEY et les cartes VISA délivrées par UBA)<br/>4- Les requête peuvent être envoyé vers le server Messenka de deux manière ; soit par données mobiles, soit par sms et dans ce cas, un server sms achemine ces requêtes vers l'url de l'opérateur mobile money. L'API est intégré sur le server de données Messenka pour traiter ces données pour qu'elles soient conforme aux normes (type de données et sécurité) de l'opérateur mobile money.`
  },
  {
    id:11,
    offre: "Schema",
    description: "<img src='images/schema.png' alt='' />"
  },
]

export default aboutData;