import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { useContext, useEffect, useState } from "react";
import { userInfoToPrintContext } from "../context/UserInfoToPrint";
import axios from "axios"
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import { checkDeclarationById } from "../../Url";
import generatePDF, { Resolution, Margin } from 'react-to-pdf';
import RealReceiptPaiement from "./RealReceipt/RealReceiptPaiement";
import "../style/receipt.css"
import ModalReceipt from "../components/ModalReceipt"

const RecuPaiement = () => {
    //receiptAigleID will be the ID of the transactions
    const { walletID, receiptAigleID } = useParams();
    const { userInfoToPrint, setUserInfoToPrint } = useContext(userInfoToPrintContext);
    const navigate = useNavigate()
    const location = useLocation();

    const [openReceipt, setOpenReceipt] = useState(false);
    const [stateDownload, setStateDownload] = useState("");

    useEffect(() => {
        if (walletID === null || receiptAigleID === null) navigate(`/error`)
    }, [location.pathname])

    useEffect(() => {
        const handleGetReceipt = async () => {
            await axios.get(`${process.env.REACT_APP_API_READTRANSACTION_SPECIFIC}/${receiptAigleID}`, {
                headers: { "Access-Control-Allow-Origin": "*" }
            })
                .then(response => {
                    setUserInfoToPrint({
                        id: response.data.id,
                        reference: response.data.reference,
                        marchant: response.data.marchant,
                        type: response.data.type,
                        statut: response.data.statut,
                        isWaiting: response.data.isWaiting,
                        montant: response.data.montant,
                        devise: response.data.devise,
                        dateInitialisation: response.data.createdAt,
                        dateUpdated: response.data.updatedAt,
                        canal: response.data.canal,
                        description: response.data.description,
                        clientNumber: response.data.client,
                        transactionid: response.data.transactionid === null ? "Pas fourni" : response.data.transactionid
                    })
                })
                .catch(error => console.log(error))

        }

        handleGetReceipt()
    }, [walletID, receiptAigleID])

    const Print = () => {
        //console.log('print');  
        let printContents = document.getElementById('printablediv').innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
    }

    const options = {
        filename: `recu_aigle_${userInfoToPrint?.reference}_${userInfoToPrint?.clientNumber}_${userInfoToPrint?.dateUpdated}_${userInfoToPrint?.montant}${userInfoToPrint?.devise}.pdf`,
        method: "save",
        // default is Resolution.MEDIUM = 3, which should be enough, higher values
        // increases the image quality but also the size of the PDF, so be careful
        // using values higher than 10 when having multiple pages generated, it
        // might cause the page to crash or hang.
        resolution: Resolution.EXTREME,
        page: {
            // margin is in MM, default is Margin.NONE = 0
            margin: Margin.SMALL,
            // default is 'A4'
            format: "letter",
            // default is 'portrait'
            orientation: "landscape"
        },
        canvas: {
            // default is 'image/jpeg' for better size performance
            mimeType: "image/jpeg",
            qualityRatio: 1
        },
        // Customize any value passed to the jsPDF instance and html2canvas
        // function. You probably will not need this and things can break,
        // so use with caution.
        overrides: {
            // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
            pdf: {
                compress: true
            },
            // see https://html2canvas.hertzen.com/configuration for more options
            canvas: {
                useCORS: true
            }
        }
    };

    const getTargetElement = () => document.getElementById("pdfSaveInFile");

    const downloadPdf = () => {
        console.log("Its going to download");
        setOpenReceipt(true);
        setStateDownload("loading");
        generatePDF(getTargetElement, options).then(response => {console.log("finish");setStateDownload("success"); console.log(stateDownload)}, onrejected => {console.log("finish");setStateDownload("error"); console.log(stateDownload)})
    };

    return (
        <div className="bg-receipt-blank">
            <ModalReceipt openReceipt={openReceipt} actionOpenReceipt={() => setOpenReceipt(false)} stateDownload={stateDownload} />
            <div className="sticky top-0 flex justify-center pt-14 gap-x-3">
                <Button variant="contained" color='primary' style={{ width: "30%" }} onClick={Print}>Cliquez pour imprimer</Button>
                <Button variant="contained" color="error" style={{ width: "30%" }} onClick={downloadPdf}>Cliquez pour télécharger en format PDF</Button>
            </div>

            <div id="pdfSaveInFile">
                <RealReceiptPaiement userInfoToPrint={userInfoToPrint} />
            </div>
        </div>
    )
}

export default RecuPaiement