import React from 'react';

import { Link } from 'react-router-dom';

const ErrorPaymentPage = () => {
  return (
    <>
    
    <div className='body'>
      
      <div class="mars"></div>
      <img src="/svg/404.svg" class="logo-404" alt='404' />
      <img src="/svg/meteor.svg" class="meteor" alt='meteor'/>
      <p class="title">Oh non!!</p>
      <p class="subtitle">
        Il semble que vous n'êtes pas connecté a internet. <br /> Vérifier votre connexion internet ou réactualiser la page.
      </p>
      <div className="text-white text-center">
        Veuillez re-scanner le QR Code du marchant
      </div>
      <img src="/svg/astronaut.svg" class="astronaut" />
      <img src="/svg/spaceship.svg" class="spaceship" />
      
    </div>
    </>
    
  );
};

export default ErrorPaymentPage;