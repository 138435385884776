import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import UserInfoToPrintProvider from "./context/UserInfoToPrint";
import 'react-phone-number-input/style.css'
import './style/specialStylePhoneInput.css'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <UserInfoToPrintProvider>
      <App />
    </UserInfoToPrintProvider>
  </React.StrictMode>
);


