import { Routes } from 'react-router-dom';
import PaymentPage from './pages/PaymentPage';
import AboutsUsPage from './pages/AboutsUsPage';
import ErrorPaymentPage from './pages/ErrorPaymentPage';
import "./style/index.css"
import "./style/form.css"
import "./style/style.css"
import "./style/tailwindColor.css"
import "./style/404.css"
import { HashRouter as Router, Route } from 'react-router-dom';
import RecuPaiement from './Pdf/RecuPaiement';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/:walletID" element={<PaymentPage />} />
          <Route path="/:walletID/:receiptAigleID" element={<RecuPaiement />} />
          <Route path="/about-us" element={<AboutsUsPage />} />
          <Route path="/error" element={<ErrorPaymentPage />} />
          <Route path="*" element={<ErrorPaymentPage />} /> 
        </Routes>
      </Router>
    </>
  );
}

export default App;
