import { Box, Button, ButtonBase, Modal } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function ModalReceipt({ openReceipt, actionOpenReceipt, stateDownload }) {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        borderRadius: 5,
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };
    return (
        stateDownload === "loading" ?
            (<Modal open={openReceipt}>
                <Box sx={{ ...style, width: "60%" }}>
                    <p className="font-semibold text-center" id="child-modal-description">
                        Nous actionnons le téléchargement. Patientez quelques minutes.
                    </p>
                    <div className="flex justify-center">
                        <div>
                            <img src="./gif/CubeLoading.gif" />
                        </div>
                    </div>
                    {/* <Button>Close Child Modal</Button> */}
                </Box>
            </Modal>)

            :

            stateDownload === "success" ?
                (
                    <Modal open={openReceipt}>
                        <Box sx={{ ...style, bgcolor: "rgb(167 243 208)", width: "60%" }}>
                            <p className="font-semibold text-center" id="child-modal-description">
                                Votre reçu a été bien téléchargé
                            </p>
                            <div className="flex justify-center">
                                <div className="h-16">
                                    <img className="h-full object-contain" src="./images/checked.png" />
                                </div>
                            </div>
                            <div className="flex justify-center relative my-7">
                                <Button className="absolute top-0 w-max" variant="contained" color="error" onClick={() => actionOpenReceipt()}>Cliquer pour fermer</Button>
                            </div>

                            {/* <Button>Close Child Modal</Button> */}
                        </Box>
                    </Modal>
                )

                :

                stateDownload === "error" ?
                    (<Modal
                        open={openReceipt}
                        onClose={() => actionOpenReceipt()}>
                        <Box sx={{ ...style, bgcolor: "rgb(254 226 226)", width: "60%" }}>
                            <p className="font-semibold text-center" id="child-modal-description">
                                Votre reçu n'a pas été téléchargé
                            </p>
                            <p className="font-semibold text-center" id="child-modal-description">
                                Raison(s) : Assurez d'avoir une connexion wifi ou credit data optimale !
                            </p>
                            <div className="flex justify-center relative">
                                <Button className="absolute top-0 w-max" variant="contained" color="error" onClick={() => actionOpenReceipt()}>Cliquer pour fermer</Button>
                            </div>
                            {/* <Button>Close Child Modal</Button> */}
                        </Box>
                    </Modal>)

                    :

                    ""
    )
}