import QRCode from "react-qr-code"

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { useEffect, useState } from "react";

const RealReceiptPaiement = ({ userInfoToPrint }) => {
    const pay_date = userInfoToPrint?.dateUpdated.split('').slice(0, 10).join('')
    const pay_hour = userInfoToPrint?.dateUpdated.split('').slice(11, 16).join('')
    const [row, setRow] = useState([]);

    console.log(userInfoToPrint)

    /* Declaration Table */
    function createData(type, reference, clientNumber, marchant, montant, devise) {
        return {
            type,
            reference,
            clientNumber,
            marchant,
            montant,
        };
    }

    const headCells = [
        {
            id: 'type',
            numeric: false,
            disablePadding: true,
            label: 'Type',
        },
        {
            id: 'reference',
            numeric: true,
            disablePadding: false,
            label: 'Reference',
        },
        {
            id: 'clientNumber',
            numeric: true,
            disablePadding: false,
            label: 'Numero Client',
        },
        {
            id: 'marchant',
            numeric: true,
            disablePadding: false,
            label: 'Numero Marchant',
        },
        {
            id: 'montant',
            numeric: true,
            disablePadding: false,
            label: 'Montant',
        },
    ];

    useEffect(() => {
        setRow([
            createData(
                userInfoToPrint?.type,
                userInfoToPrint?.reference,
                userInfoToPrint?.clientNumber,
                userInfoToPrint?.marchant,
                userInfoToPrint?.montant+' '+userInfoToPrint?.devise),
        ]);
    }, [userInfoToPrint])


    return (
        <div className="bg-receipt-content shadow mx-3" id='printablediv'>
            <header className="text-center py-2 text-secondary">--- Reçu généré par AIGLE ---</header>

            <div className="mt-1 p-3 border border-b-black border-b-black info-receipt container">
                <div className="flex justify-between items-center">
                    <div>
                        <span className="font-bold">Reçu n°{userInfoToPrint?.reference} TRANSACTION {userInfoToPrint?.canal} vers AIGLE</span>
                    </div>

                    <div className="recu-containDivImg">
                        <div className="recu-containDivImg-containImg">
                            <img src={"/images/ohno_aigle.png"} className="" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="info-small-toPayment mt-4 flex justify-between container">
                <div>
                    <h4 className="my-2 font-bold"><u>Informations sur le client & la transaction</u></h4>
                    <div>
                        <span className="font-bold">Numéro du client</span> : {userInfoToPrint?.clientNumber}
                    </div>
                    <div>
                        <span className="font-bold">Identifiant de la transaction</span> : {userInfoToPrint?.id}
                    </div>
                </div>

                <div>
                    <h4 className="my-2 font-bold"><u>Informations sur le marchant (receveur)</u></h4>
                    <div>
                        <span className="font-bold">Numéro d'identification du marchand</span> :  {userInfoToPrint?.marchant}
                    </div>
                    <div>
                        <span className="font-bold">Numéro du marchant</span> : {userInfoToPrint?.marchant}
                    </div>

                    <div>
                        {userInfoToPrint?.dateInitialisation !== userInfoToPrint?.dateUpdated && <span><span className="font-bold">Date de paiement</span> : {pay_date}</span>}
                        {userInfoToPrint?.dateInitialisation === userInfoToPrint?.dateUpdated && <span><span className="font-bold">Date de paiement</span> : Inconnu</span>}
                    </div>
                    <div>
                        {userInfoToPrint?.dateInitialisation !== userInfoToPrint?.dateUpdated && <span><span className="font-bold">Heure de paiement</span> : {pay_hour}</span>}
                        {userInfoToPrint?.dateInitialisation === userInfoToPrint?.dateUpdated && <span><span className="font-bold">Heure de paiement</span> : Inconnu</span>}
                    </div>
                    <div className="mt-1 flex justify-start items-center">
                        <span className="font-bold">Statut de la transaction</span> :

                        {userInfoToPrint?.statut === "failed" &&
                            <div className="bg-red-500 p-2 mx-2 recu-notification">Paiement échoué</div>}

                        {userInfoToPrint?.statut === "pending" &&
                            <div className="bg-blue-500 p-2 mx-2 recu-notification">En attente du paiement</div>}

                        {userInfoToPrint?.statut === "success" &&
                            <div className="bg-green-700 p-2 mx-2 recu-notification">Succès</div>}
                    </div>
                </div>
            </div>

            <div className="info-big-toPayment w-full mt-5">

                {userInfoToPrint?.statut === "success" && (
                    <>
                        <h2 className="p-3 font-bold container">DETAIL SUR LE PAIEMENT</h2>
                        <TableContainer style={{ width: "100%", display : "flex", justifyContent : "center" }}>
                            <Table style={{ width: "90%" }}>
                                <TableHead className="bg-[#0aa9c6] text-white">
                                    <TableRow>
                                        {headCells.map((headCell) => (
                                            <TableCell
                                                key={headCell.id}
                                                align={headCell.numeric ? 'right' : 'left'}
                                                padding={headCell.disablePadding ? 'none' : 'normal'}
                                            >
                                                <TableSortLabel>
                                                    {headCell.label}
                                                </TableSortLabel>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row?.map((row, index) => {
                                        const labelId = `enhanced-table-checkbox-${index}`;
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={row.reference}
                                                sx={{ cursor: 'pointer' }}
                                            >
                                                <TableCell
                                                    component="th"
                                                    id={labelId}
                                                    scope="row"
                                                    padding="none"
                                                >
                                                    {row.type}
                                                </TableCell>
                                                <TableCell component="th" align="right">{row.reference}</TableCell>
                                                <TableCell component="th" align="right">{row.clientNumber}</TableCell>
                                                <TableCell component="th" align="right">{row.marchant}</TableCell>
                                                <TableCell component="th" align="right">{row.montant} {row.devise}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>)}

                {userInfoToPrint?.statut === "failed" && (<h2 className="p-3 container text-center font-bold">VOTRE TRANSACTION A ÉCHOUÉ(E). VEUILLEZ REESSAYER OU CONTACTER LE SERVICE TECHNIQUE DE AIGLE</h2>)}

                {userInfoToPrint?.statut === "pending" && (<h2 className="p-3 container text-center font-bold">VOTRE TRANSACTION EST EN ATTENTE. VEUILLEZ LA FINALISER</h2>)}
            </div>

            <div className="container info-addiontional-toPayment flex justify-start items-center mt-5">
                {/* <div className="receipt-containDivCodeQR">
                    <div className="receipt-containDivCodeQR-containCodeQR px-2">
                        <QRCode size={256} viewBox={`0 0 256 256`} style={{ height: "100%", width: "100%" }} value={`https://www.rdv-etatcivil.cloud/receipt_demande/${userInfoToPrint?.reference}`} />
                    </div>
                </div> */}

                {/* <div>
                    Ce code QR sera utile pour des vérifications rapides au niveau de AIGLE.
                </div> */}
            </div>

            <div className="text-center py-4">
                <h4>NOUS VOUS REMERCIONS POUR VOTRE CONFIANCE !</h4>
            </div>

            <footer className="footer-receipt p-3 flex justify-center">
                <div class="container text-center">
                    <small>Copyright &copy; FINTECH AIGLE</small>
                </div>
            </footer>
        </div>
    )
}

export default RealReceiptPaiement;