import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
    <>   
        <div class="container" style={{padding:0}}>
          <div class="header-area">
            <div class="logo"> <Link to={"/"}> <img src="./images/aigle.png" alt="CsnPay" /> </Link> </div>
            <ul class="links">
              <li> <Link to={"/about-us"} class="active"> Qui sommes nous ? </Link></li>
            </ul>
          </div>
        </div>
    </>
  );
};

export default Navbar;